import { useEffect } from 'react';
import _throttle from 'lodash.throttle';
import { useRouter } from 'next/router';

// utils
import { useTracking } from 'utils/hooks/useTracking';
import { scrollDepth } from './trackingActions';
import { useTracking as useTracking2 } from 'utils/tracking/track';

const TO_TRACK_SCROLL_DEPTH = [10, 25, 50, 75, 90, 100];

export function useTrackScroll() {
  const track = useTracking();
  const { trackScroll } = useTracking2();
  const router = useRouter();
  const pathname = router.asPath;

  useEffect(() => {
    // we do not want to track the scrolling that occurs due to page changes.
    let shouldTrack = false;
    const clone = TO_TRACK_SCROLL_DEPTH.slice(0);
    let percent = clone.shift();
    const throttledTrackScrollDepth = _throttle(() => {
      if (!shouldTrack) return;

      const scrollPosition = window.scrollY + window.innerHeight;
      const pageHeight = document.body.scrollHeight;
      const currScrollDepth = Math.round((scrollPosition / pageHeight) * 100);

      if (percent) {
        if (currScrollDepth >= percent) {
          track.trackEvent(scrollDepth(pathname, percent));
          trackScroll(percent);

          // get value and remove it from the list so that we do not track an event twice
          percent = clone.shift();
        }
      }
    }, 100);

    setTimeout(() => {
      // after a while every scroll is caused by user,
      // we want to track that of course
      shouldTrack = true;
    }, 150);
    window.addEventListener('scroll', throttledTrackScrollDepth);

    return () => {
      window.removeEventListener('scroll', throttledTrackScrollDepth);
    };
  }, [pathname, track, trackScroll]);
}
