import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';

// uTils
import {
  webshopMaintenanceEnabledSelector,
  webshopMaintenanceLoadingSelector,
} from 'utils/selectors/maintenanceSelectors';
import { webshopLandingpageUrlSelector } from 'utils/selectors/globalsSelectors';

export function useWebshopMaintenance() {
  const maintenanceEnabled = useSelector(webshopMaintenanceEnabledSelector);
  const maintenanceLoading = useSelector(webshopMaintenanceLoadingSelector);
  const webshopHomepage = useSelector(webshopLandingpageUrlSelector);
  const router = useRouter();
  const pathname = router.asPath;

  return pathname === webshopHomepage && maintenanceEnabled && !maintenanceLoading;
}
