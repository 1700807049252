import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';

// components
import { Lightbox } from '../LightBox/Lightbox';
import { TitleFormats, Title } from '../ContentElements/Title/Title';
import { InnerHtml } from '../InnerHtml/inner-html';
import { BUTTON_TYPES, Button } from '../Button/Button';

// styles
import styles from './splashScreen.scss';

// selectors
import { splashScreenSelector } from '../../utils/selectors/globalsSelectors';
import { useSplashScreen as useShowSplashScreen } from '../Page/use-splash-screen';

function SplashScreen() {
  const [isOpen, setIsOpen] = useState(true);
  const splashScreen = useSelector(splashScreenSelector);
  const show = useShowSplashScreen();

  if (isEmpty(splashScreen) || !show) return null;

  const { headline, subline, text } = splashScreen;

  return isOpen ? (
    <Lightbox onCloseRequest={() => setIsOpen(false)}>
      <div className="c-lightbox__container">
        <header className={styles.lightboxHeader}>
          <Button symbol="close" type={BUTTON_TYPES.PLAIN} onClick={() => setIsOpen(false)} />
        </header>
        <div className={styles.lighboxContent}>
          <Title title={headline} subtitle={subline} Format={TitleFormats.h1} />
          <InnerHtml content={text} as="p" />
        </div>
      </div>
    </Lightbox>
  ) : null;
}

export default SplashScreen;
