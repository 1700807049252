import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

// utils
import { currentPageSelector } from 'utils/selectors/pageSelectors';

export function useSplashScreen() {
  const page = useSelector(currentPageSelector);
  const router = useRouter();
  const pageType = page?.type;
  const pathname = router.asPath;
  const hash = pathname.split('#')?.[1] || '';

  return useMemo(() => {
    if (hash) {
      if ((hash.match(/#/g) || []).length > 1) {
        const hashes = decodeURIComponent(hash.split('#')?.[1]);

        // SplashScreen should only show on content- and landingpages
        return (
          hashes === '#redirect' && (pageType === 'content_page_100' || pageType === 'landingpage')
        );
      }
    }

    return false;
  }, [hash, pageType]);
}
