import { trackTemplate } from '../utils/tracking';

/**
 * Tab click
 * @param string text
 */
export function urlChange(title, path) {
  return trackTemplate({
    event: 'genericPageview',
    data: {
      title,
      path,
    },
  });
}
