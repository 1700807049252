// components
import Footer from '../../components/Footer/Footer';

function SceneNotFound() {
  return (
    <>
      <main>
        <div className="grid-container">
          <h1>Scene not found!</h1>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default SceneNotFound;
