import dynamic from 'next/dynamic';

const Default = dynamic(() =>
  import('../../scenes/Default/Default').then((module) => module.Default),
);

const Home = dynamic(() => import('../../scenes/Home/Home').then((module) => module.Home));

const NotFound = dynamic(() =>
  import('../../scenes/Error/NotFound').then((module) => module.NotFound),
);

const ProductDetail = dynamic(() =>
  import('../../scenes/Product/ProductDetail').then((module) => module.ProductDetail),
);

const ProductListing = dynamic(() =>
  import('../../scenes/Product/ProductListing').then((module) => module.ProductListing),
);

const VideocenterOverview = dynamic(() =>
  import('../../scenes/Video/VideocenterOverview').then(
    (module) => module.VideocenterOverviewScene,
  ),
);

const VideocenterChannel = dynamic(() =>
  import('../../scenes/Video/VideocenterChannel').then((module) => module.VideocenterChannel),
);

const DownloadCenterPageSelector = dynamic(() =>
  import('../../features/download-center/scenes/entry').then((module) => module.DownloadCenterPage),
);

const Frameless = dynamic(() =>
  import('../../scenes/Frameless/Frameless').then((module) => module.Frameless),
);

const Landingpage = dynamic(() =>
  import('../../scenes/LandingPage/Landingpage').then((module) => module.Landingpage),
);

const Search = dynamic(() => import('../../scenes/Search/Search').then((module) => module.Search));

const CheckoutIntro = dynamic(() =>
  import('../../scenes/ShoppingBasket/CheckoutIntro/CheckoutIntro').then(
    (module) => module.CheckoutIntro,
  ),
);

const ShoppingBasket = dynamic(() =>
  import('../../scenes/ShoppingBasket/ShoppingBasket').then((module) => module.ShoppingBasket),
);

const Checkout = dynamic(() =>
  import('../../scenes/Checkout/Checkout').then((module) => module.Checkout),
);

const OrderHistory = dynamic(() =>
  import('../../scenes/OrderHistory/OrderHistory').then((module) => module.OrderHistory),
);

const CampusLanding = dynamic(() =>
  import('../../scenes/Campus/CampusLanding').then((module) => module.CampusLanding),
);

const CampusHome = dynamic(() =>
  import('../../scenes/Campus/CampusHome').then((module) => module.CampusHome),
);

const CampusCatalog = dynamic(() =>
  import('../../scenes/Campus/CampusCatalog').then((module) => module.CampusCatalog),
);

const CampusCourseDetails = dynamic(() =>
  import('../../scenes/Campus/CampusCourseDetails').then((module) => module.CampusCourseDetails),
);

const CampusCourseDetailsBooked = dynamic(() =>
  import('../../scenes/Campus/CampusCourseDetailsBooked').then(
    (module) => module.CampusCourseDetailsBooked,
  ),
);

const CampusCourseRegistration = dynamic(() =>
  import('../../scenes/Campus/CampusCourseRegistration').then(
    (module) => module.CampusCourseRegistration,
  ),
);

const GroupHome = dynamic(() => import('../../scenes/Group/Home').then((module) => module.Home));

const GroupLanding = dynamic(() =>
  import('../../scenes/Group/Landing').then((module) => module.Landing),
);

const GroupDetail = dynamic(() =>
  import('../../scenes/Group/Detail').then((module) => module.Detail),
);

const GroupInsightsDetail = dynamic(() =>
  import('../../scenes/Group/InsightsDetail').then((module) => module.InsightsDetail),
);

const GroupInsightsLanding = dynamic(() =>
  import('../../scenes/Group/InsightsLanding').then((module) => module.InsightsLanding),
);

const ShowroomLocator = dynamic(() =>
  import('../../scenes/showroom-locator/locator-scene').then((module) => module.LocatorScene),
);

const NordicsContent = dynamic(() =>
  import('../../scenes/Nordics/content').then((module) => module.Content),
);

const NordicsLanding = dynamic(() =>
  import('../../scenes/Nordics/landing').then((module) => module.Landing),
);

const NordicsHome = dynamic(() =>
  import('../../scenes/Nordics/home').then((module) => module.Home),
);

const PressPortal = dynamic(() =>
  import('../../scenes/Nordics/press-portal/press-portal-scene').then(
    (module) => module.PressPortalScene,
  ),
);

const PressPortalDetailPage = dynamic(() =>
  import('../../scenes/Nordics/press-portal/press-portal-detail-page-scene').then(
    (module) => module.PressPortalDetailPageScene,
  ),
);

const PressPortalAssetDetailPage = dynamic(() =>
  import('../../scenes/Nordics/press-portal/press-portal-asset-detail-page-scene').then(
    (module) => module.PressPortalAssetDetailPageScene,
  ),
);

const CiamDeletionPage = dynamic(() =>
  import('../../scenes/Group/ciam-deletion-page').then((module) => module.CiamDeletionPage),
);

export const scenes = {
  content_page_100: Default,
  content_page_clean: Default,
  content_page_66_33: Default,
  content_page_no_header_footer: Frameless,
  error_page: NotFound,
  homepage: Home,
  product_detail_page: ProductDetail,
  product_listing_page: ProductListing,
  videocenter_overview: VideocenterOverview,
  videocenter_channel: VideocenterChannel,
  downloadcenter: DownloadCenterPageSelector,
  landingpage: Landingpage,
  order_history: OrderHistory,
  search_results: Search,
  checkout_intro: CheckoutIntro,
  basketpage: ShoppingBasket,
  checkout: Checkout,
  campus_landingpage: CampusLanding,
  campus_dashboard: CampusHome,
  campus_catalogpage: CampusCatalog,
  campus_course_detail: CampusCourseDetails,
  campus_course_detail_participant: CampusCourseDetailsBooked,
  campus_courseregistrationpage: CampusCourseRegistration,
  group_homepage: GroupHome,
  group_landingpage: GroupLanding,
  group_detailpage: GroupDetail,
  group_insights_detailpage: GroupInsightsDetail,
  group_insights_landingpage: GroupInsightsLanding,
  showroom_locator: ShowroomLocator,
  nordics_contentpage: NordicsContent,
  nordics_landingpage: NordicsLanding,
  nordics_homepage: NordicsHome,
  nordics_videocenter_overview: VideocenterOverview,
  press_portal: PressPortal,
  press_portal_detail_page: PressPortalDetailPage,
  press_portal_asset_detail_page: PressPortalAssetDetailPage,
  ciam_account_deletion: CiamDeletionPage,
};
