import { useContext, useEffect, useState } from 'react';
import { DialogueProvider } from '@geberit/gdds';
import { useRouter } from 'next/router';

// components
import { scenes } from './scenes';
import ErrorView from 'scenes/Error/Error';
import NordicsError from 'scenes/Error/NordicsError';
import OfflineView from 'scenes/Offline';
import SceneNotFound from 'scenes/Error/SceneNotFound';
import NordicsNotFound from 'scenes/Nordics/error/not-found';
import MetaData, { IsoLangProvider } from 'scenes/MetaData';
import SplashScreen from '../SplashScreen/SplashScreen';
import { ContentContextProvider } from '../ContentArea/ContentContext';
import { EditingProvider } from '../ContentCreator/EditingContext';
import { CookieConsentProvider } from 'features/cookie-consent';
import { ParentProvider } from 'scenes/parent-provider';

// constants
import { JOBS_ROUTES } from './job-routes';

// utils
import { useIsomorphicLayoutEffect } from 'utils/hooks/use-isomorphic-layout-effect';
import { useCiamLogin } from 'utils/hooks/useCiam';
import { addCiamHeader, SessionContext } from '../Session/SessionContext';
import { useNord } from 'utils/hooks/use-nord';
import { useTrackPage } from './use-track-page';
import { useTrackScroll } from './use-track-scroll';
import { useScrollToItem } from './use-scroll-to-item';
import { useRedirectUrl } from './use-redirect-url';
import { useSessionId } from './use-session-id';
import { PrevLocationContextProvider } from './prev-location-context';

type PageProps = {
  language: string;
  page: AppState['page'][string];
};

export function Page({ language, page }: Readonly<PageProps>) {
  const pageType = page?.type as keyof typeof scenes | 'job_search' | undefined;
  const router = useRouter();
  const pathname = router.asPath;
  const {
    state: { gigyaWindow },
  } = useContext(SessionContext);
  const [is404, setIs404] = useState(false);
  const { init, role } = useCiamLogin(gigyaWindow, language);
  const isNord = useNord();

  useEffect(() => {
    if (gigyaWindow && !role) {
      init();
    }

    if (role) {
      addCiamHeader(gigyaWindow);
    }
  }, [gigyaWindow, role, pathname, init]);

  useEffect(() => {
    if (pageType === 'job_search') {
      window.location = JOBS_ROUTES[language];
    }
  }, [language, pageType]);

  useTrackPage(page);
  useTrackScroll();
  useScrollToItem(page);
  useSessionId();
  const redirectUrl = useRedirectUrl(role, pageType);

  useIsomorphicLayoutEffect(() => {
    if (isNord && pageType === 'error_page' && !is404) {
      setIs404(true);
    }
  }, [pageType, isNord, is404]);

  // display error
  if (page?.error) {
    if (page.error.status === 0) {
      return <OfflineView />;
    }
    return isNord ? <NordicsError /> : <ErrorView />;
  }

  if (redirectUrl) {
    router.replace(redirectUrl);
    return null;
  }

  const metaLanguage = page.metaData?.lang ?? language;

  if (isNord && pageType === 'error_page' && is404) {
    return <NordicsNotFound />;
  }

  if (!pageType || !language) {
    return null;
  }

  const Scene = scenes[pageType];

  if (!is404 && (!Scene || pageType === 'error_page')) {
    setIs404(true);
  }

  if (!Scene) {
    return <SceneNotFound />;
  }

  return (
    <ParentProvider value={{ parent: 'page', pageType }}>
      <IsoLangProvider lang={metaLanguage}>
        <MetaData lang={language} />
        <SplashScreen />
        <CookieConsentProvider>
          <EditingProvider>
            <ContentContextProvider>
              <DialogueProvider>
                <PrevLocationContextProvider>
                  <Scene {...page} />
                </PrevLocationContextProvider>
              </DialogueProvider>
            </ContentContextProvider>
          </EditingProvider>
        </CookieConsentProvider>
      </IsoLangProvider>
    </ParentProvider>
  );
}
