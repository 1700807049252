import { useRouter } from 'next/router';

// utils
import { useIsomorphicLayoutEffect } from 'utils/hooks/use-isomorphic-layout-effect';
import { useIsPreview } from 'utils/hooks/useIsPreview';

const isCC = (isPreview: boolean) =>
  isPreview && typeof window !== 'undefined' && window.location !== window.parent.location;

export function useScrollToItem(page: AppState['page'][string]) {
  const isPreview = useIsPreview();
  const router = useRouter();
  const pathname = router.asPath;
  const hash = pathname.split('#')?.[1] || '';

  useIsomorphicLayoutEffect(() => {
    let timeout: Timeout | undefined;

    if (!isCC(isPreview) && !hash) {
      window.scrollTo(0, 0);
    }

    if (isCC(isPreview)) {
      const scrollToItem = window.sessionStorage.getItem('cc_scroll');
      timeout = setTimeout(() => {
        if (scrollToItem) window.scrollTo(0, parseInt(scrollToItem));
        window.sessionStorage.removeItem('cc_scroll');
      }, 300);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [page]);
}
