import { GetServerSidePropsContext } from 'next';
import { useSelector } from 'react-redux';
import { addSeconds } from 'date-fns';
import { serialize } from 'cookie';

// components
import MaintenancePage from 'scenes/ShoppingBasket/Maintenance/Maintenance';
import { Page } from '../components/Page/Page';
import { ContentContextProvider } from 'components/ContentArea/ContentContext';

// utils
import { generateDealerUrl } from 'components/ContentElementsGdds/locator/utils/generate-dealer-url';
import { Service } from 'services';
import { createInitialStore } from 'services/store';
import { getInitialPageData } from 'services/initial-data';
import { CampusLms } from 'services/campus-lms';
import { Log } from 'services/log';
import { hashCode } from 'utils/hash';
import { generateSlug } from 'utils/slug';
import { currentPageSelector } from 'utils/selectors/pageSelectors';
import { getServerPageProps } from 'utils/get-server-vars';
import { useWebshopMaintenance } from 'utils/hooks/use-webshop-maintenance';
import { mapLocatorData } from '../components/ContentElementsGdds/locator/use-map-locator-data';

// module is called internally by nextjs
// eslint-disable-next-line import/no-unused-modules
export default function EntryPage({ currentLanguage }: Readonly<{ currentLanguage: string }>) {
  const page = useSelector(currentPageSelector);
  const webshopMaintenance = useWebshopMaintenance();

  if (page.type === 'new_page_from_tpp') {
    return null;
  }

  // when maintenance is enabled show this page instead of webshop landingpage
  if (webshopMaintenance) {
    return (
      <ContentContextProvider>
        <MaintenancePage />
      </ContentContextProvider>
    );
  }

  return <Page page={page} language={currentLanguage} />;
}

// module is called internally by nextjs
// eslint-disable-next-line import/no-unused-modules
export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  // disable permanent redirect in dev mode
  const permanent = process.env.IS_LOCAL !== '1';

  const { req, res, query } = ctx;

  // the indexer service makes a HEAD request to get only the headers. the body of a head request is empty.
  // but if we pass the HEAD method down to the services we will get a 500, because of empty bodies :)
  // so we do a get request instead to response with the right headers.
  if (req.method === 'HEAD') {
    req.method = 'GET';
  }
  const slug = query.slug as string[] | undefined;
  if (!req.url?.includes('.htm') && req.url === `/${slug?.join('/')}`) {
    return {
      redirect: {
        destination: req.url + '/',
        permanent,
      },
    };
  }

  const { homepages } = await Service('navigationservice', req).json('/navigation/homepagelist');
  // redirect to home page
  if (!slug) {
    const destination = homepages.find((h) => h.isMasterLanguage)?.url;
    if (!destination) {
      return { notFound: true };
    }
    return {
      redirect: {
        destination,
        permanent,
      },
    };
  }

  /*
   * handle campus session callback
   */
  if (query.access_token && query.refresh_token) {
    const accessCookie = serialize('campus_access_token', query.access_token, {
      path: '/',
      expires: addSeconds(new Date(), 3600),
      secure: true,
    });
    const refreshCookie = serialize('campus_refresh_token', query.refresh_token, {
      path: '/',
      // TODO get correct expires date. for now just make the refresh token longer available then the
      // access token, so we can refresh the access token
      expires: addSeconds(new Date(), 7200),
      secure: true,
    });
    res.setHeader('set-cookie', [accessCookie, refreshCookie]);
    return {
      redirect: {
        destination: `/${slug.join('/')}/`,
        permanent: false,
      },
    };
  }
  let pageUrl = `/${slug.join('/')}`;
  if (!pageUrl.endsWith('.html') && !pageUrl.endsWith('/')) {
    pageUrl += '/';
  }
  try {
    let page = await Service('contentservice', req).json(`/content?url=${pageUrl}`);
    // if the content service doesn't find a page and we got the query params from the
    // tpp it's a new page
    if (page && page.type === 'error_page' && query.preview_id && query.currentLanguage) {
      page = {
        type: 'new_page_from_tpp',
        previewId: query.preview_id,
        currentLanguage: query.currentLanguage,
      };
    }
    /*
     * custom locator detail page handler
     */
    if (page && page.type === 'error_page') {
      const parentSlug = slug.slice(0, slug.length - 1).join('/');
      const parentPage = await Service('contentservice', req).json(`/content?url=/${parentSlug}/`);
      if (parentPage.type === 'showroom_locator') {
        const locale = parentPage.metaData?.lang.split('-')[0];
        const showroom = mapLocatorData(parentPage.data?.showroom?.[0], parentPage.metaData?.lang);
        const { entries } = showroom;
        const dealerSlug = slug.slice(-1)[0];
        const dealer = entries?.find((e) => generateDealerUrl(e, locale) === dealerSlug);

        if (dealer) {
          page = { ...parentPage, dealer };
        }
      }
    }
    const pageId = hashCode(slug.join('/'));

    res.setHeader('Cache-Control', 'public, s-maxage=10, stale-while-revalidate=59');

    if (!page || page.type === 'error_page') {
      return {
        notFound: true,
      };
    }

    const technicalLanguage = page.currentLanguage || page.fs_language;
    const initalPageData = await getInitialPageData(page, req);

    const maintenanceCheck = await Service('maintenanceservice', req).json(
      `/info?lang=${technicalLanguage}`,
    );
    const initialStore = await createInitialStore(homepages, technicalLanguage, req);

    if (query.accesscode) {
      const referenceUrl = initialStore.globals[technicalLanguage].campus.campusReferenceUrl;
      const { accessToken, refreshToken, expiresIn } = await Service('campusservice', req).json(
        `/api/campus/token?username=${query.accesscode}`,
      );
      if (accessToken && refreshToken && expiresIn) {
        const { id } = await CampusLms(accessToken).json('/lms/users/@self');
        const { name: courseName } = await Service('campusservice', req).json(
          `/api/campus/booked-course-details/?course_id=${page.courseId}&user_id=${id}&lang=${technicalLanguage}&access_token=${accessToken}`,
        );
        const destination = `${referenceUrl}/${generateSlug(courseName)}/crs_${
          page.courseId
        }_pro_${id}-${technicalLanguage}/`;
        const accessCookie = serialize(`campus_access_token_${page.courseId}`, accessToken, {
          path: '/',
          expires: addSeconds(new Date(), expiresIn),
          secure: true,
        });
        const refreshCookie = serialize(`campus_refresh_token_${page.courseId}`, refreshToken, {
          path: '/',
          // TODO get correct expires date. for now just make the refresh token longer available then the
          // access token, so we can refresh the access token
          expires: addSeconds(new Date(), expiresIn * 2),
          secure: true,
        });
        res.setHeader('set-cookie', [accessCookie, refreshCookie]);
        return {
          redirect: {
            // this information will come later from query params
            destination,
            permanent: false,
          },
        };
      }
    }

    return {
      props: {
        initialStore: {
          ...initialStore,
          maintenance: { content: { ...maintenanceCheck }, loading: false },
        },
        page: { ...page, initialData: initalPageData },
        pageId,
        currentLanguage: technicalLanguage,
        ...getServerPageProps(req),
      },
    };
  } catch (ex) {
    if (ex.status === 404) {
      return {
        notFound: true,
      };
    }
    Log.error(ex);
    throw ex;
  }
}
