import React from 'react';

// components
import MetaData from '../../MetaData';
import Footer from '../../../components/Footer/Footer';
import Maintenance from '../../../components/Maintenance/maintenance';

function MaintenancePage() {
  return (
    <React.Fragment>
      <MetaData robots="noindex, nofollow" />
      <main>
        <Maintenance />
      </main>
      <Footer />
    </React.Fragment>
  );
}

export default MaintenancePage;
