// components
import Logo from '../components/Logo/Logo';

function OfflineView() {
  return (
    <>
      <header className="c-header c-header--error-page">
        <div className="grid-container">
          <Logo />
        </div>
      </header>
      <main className="error-page">
        <div className="c-error-page" style={{ backgroundImage: 'url(/images/error/404.jpg)' }}>
          <div className="grid-container">
            <div className="c-error-page__content">
              <p>Offline</p>
              <h1 className="h2">No internet connection</h1>
              <p>
                You do not seem to be connected to the internet. Please check your connection and
                try again.
              </p>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default OfflineView;
